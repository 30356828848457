@import '@angular/cdk/overlay-prebuilt.css';

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';

@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/scss/regular';
@import '@fortawesome/fontawesome-free/scss/solid';

:root {
  --color-primary: #ffb700;
  --color-primary-50: #fff8e5;
  --color-primary-100: #fff1cc;
  --color-primary-200: #ffe299;
  --color-primary-300: #ffd466;
  --color-primary-400: #ffc533;
  --color-primary-500: #ffb700;
  --color-primary-600: #db9d00;
  --color-primary-700: #b88400;
  --color-primary-800: #946a00;
  --color-primary-900: #705100;
  --color-secondary: #ff455c;
  --color-secondary-50: #ffe6e9;
  --color-secondary-100: #ffd4d9;
  --color-secondary-200: #ffb0ba;
  --color-secondary-300: #ff8c9b;
  --color-secondary-400: #ff697b;
  --color-secondary-500: #ff455c;
  --color-secondary-600: #ff1734;
  --color-secondary-700: #6b383e;
  --color-secondary-800: #ba0017;
  --color-secondary-900: #8c0011;
  --color-dark: #32425d;
  --color-dark-50: #889cbf;
  --color-dark-100: #7a91b8;
  --color-dark-200: #607ba9;
  --color-dark-300: #4f6892;
  --color-dark-400: #405578;
  --color-dark-500: #32425d;
  --color-dark-600: #273449;
  --color-dark-700: #1d2635;
  --color-dark-800: #121821;
  --color-dark-900: #070a0d;
  --color-milestone-default: #9ca3af;
  --color-milestone-progress: #3b82f6;
  --color-milestone-finished: #34d399;
  --color-smoke: rgb(46 49 49 / 30%);
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
  html {
    font-size: 16px;
    font-family: neuzeit-grotesk, -apple-system, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue',
      Helvetica, Arial, sans-serif;
    scroll-behavior: smooth;
  }

  body {
    @apply bg-gray-100 antialiased;
  }

  .overlay-container {
    @apply absolute inset-0 z-50;
  }
}

/** Buttons */

@layer components {
  .button-group {
    @apply overflow-hidden rounded-xl bg-gray-200;
  }

  .button-group > div {
    @apply inline-flex p-1 pl-2 pr-2;
  }

  .button-group .btn {
    @apply inline-flex cursor-pointer bg-gray-200 p-1 pl-2 pr-2 transition duration-100 ease-in-out hover:bg-gray-300 focus:outline-none;
  }

  .button-group .btn.active {
    @apply bg-gradient-to-r from-secondary-500 to-primary-500;
  }
}

/** Misc */

@layer components {
  .badge {
    @apply inline-flex rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800;
  }

  .badge-blue {
    @apply badge bg-blue-100 text-blue-800;
  }

  .badge-green {
    @apply badge bg-green-100 text-green-800;
  }

  .badge-red {
    @apply badge bg-red-100 text-red-800;
  }

  .badge-yellow {
    @apply badge bg-yellow-100 text-yellow-800;
  }

  .inset-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .tooltip .tooltip-text {
    @apply invisible absolute z-200 inline-flex items-center rounded-full px-3 py-1 text-base font-medium shadow;
  }

  .tooltip:hover .tooltip-text {
    @apply visible;
  }

  .sticky-header {
    @apply fixed top-0 overflow-x-scroll shadow-md;
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .crossed-out {
    background: linear-gradient(
        to top left,
        rgb(0 0 0 / 0%) 0%,
        rgb(0 0 0 / 0%) calc(50% - 0.8px),
        rgb(17 24 39 / 100%) 50%,
        rgb(0 0 0 / 0%) calc(50% + 0.8px),
        rgb(0 0 0 / 0%) 100%
      ),
      linear-gradient(
        to top right,
        rgb(0 0 0 / 0%) 0%,
        rgb(0 0 0 / 0%) calc(50% - 0.8px),
        rgb(17 24 39 / 100%) 50%,
        rgb(0 0 0 / 0%) calc(50% + 0.8px),
        rgb(0 0 0 / 0%) 100%
      );
  }
}

/** Panels */

@layer components {
  .panel {
    @apply rounded-lg bg-white p-6 shadow-md md:px-4 md:py-4;
  }

  .panel-sm {
    @apply rounded-lg bg-white p-6 shadow-md md:px-4 md:py-2;
  }
}

/** Swal */
@layer components {
  .swal2-abolute {
    position: fixed !important;
    height: auto !important;
  }
}

.notification-overlay-backdrop {
  background-color: transparent;
}
